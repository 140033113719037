import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    minDate: { type: String, default: null },
    maxDate: { type: String, default: null }
  };

  connect() {
    window.initialize_slplaner_datepicker(this.element, this.minDateValue, this.maxDateValue);
  }

  disconnect() {
    this.element.querySelectorAll('.datepicker').forEach((dateEl) => {
      const instance = M.Datepicker.getInstance(dateEl);
      if (instance && typeof instance.destroy === 'function') {
        instance.destroy();
      }
    });
  }  
}